






















































































import Vue from 'vue'

export default Vue.extend({
  middleware: ['auth'],
  data() {
    return {
      locale: 'ja',
      isDisabled: false,
    }
  },
  created() {
    // グローバルなオブジェクトにハンドラ追加
    this.$nuxt.$on('updateLocale', this.setLocale)
    this.$nuxt.$on('disableOperation', this.disableOperation)
  },
  methods: {
    setLocale(locale: string): void {
      this.locale = locale || 'ja'
    },

    /**
     * 操作を無効にする
     * @return {void}
     */
    disableOperation(): void {
      console.log('disableOperation')
      this.isDisabled = true
    },
  },
})
