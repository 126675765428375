import Vue from 'vue'
import dayjs from 'dayjs'
import { MyFolder, DangoResponse } from '@/types/dango'
import {
  getLoginInfo,
  checkMaintenance,
  checkAccessRights,
  checkSvData,
} from '@/plugins/user'
import {
  initMyFolderInfo,
  getMyFolderInfo,
  storeMyFolderInfo,
} from '@/plugins/myfolder'
import { NotFoundError } from '@/plugins/exception'
import { isSpDevice } from '@/plugins/device'
import SimpleBar from 'simplebar'
import 'simplebar/dist/simplebar.css'

let simplebar: SimpleBar
const sprintf = require('sprintf-js').sprintf

// インターフェースを追加
declare global {
  interface Element {
    msMatchesSelector(selectors: string): boolean
  }
}
interface HTMLElementEvent<T extends HTMLElement> extends Event {
  target: T
  key: any
}
declare module 'vue/types/vue' {
  interface Vue {
    $$getLoginInfo(key: string): number | string | boolean
    $$setMainVueInstance(): void
    $$getMainVueInstance(): any
    $$initMyFolderInfo(): void
    $$getMyFolderInfo(
      key1: keyof MyFolder.detailIF,
      key2:
        | keyof MyFolder.quoteJsonIF
        | keyof MyFolder.presearchJsonIF
        | keyof MyFolder.favoriteKijiJsonIF
        | keyof MyFolder.notePlusJsonIF
        | ''
    ): any
    $$checkMaintenance(path: string): boolean
    $$checkAccessRights(path: string): boolean
    $$checkSvData(key: string): boolean
    $$changeLoadingFlg(flg: number): void
    $$showLoading(): void
    $$hideLoading(): void
    $$initializePage(classNames: string[]): void
    $$validate(): Promise<string>
    $$resetKeyword(): void
    $$addWordToKeyword(word: string): void
    $$addWordToMenName(words: string[]): void
    $$getDateOption(type: string): string[][]
    $$setDate(): void
    $$resetDate(): void
    $$updateTextBox(name: string): void
    $$updateListBox(name: string, i: number, gflg: number): void
    $$clearListBox(name: string): void
    $$selectAllKijiIdBox(): void
    $$clearAllKijiIdBox(): void
    $$scrollToTop(e: HTMLElementEvent<HTMLElement>): void
    $$adjustScrollPosition(e: HTMLElementEvent<HTMLElement>): void
    $$switchTab(e: HTMLElementEvent<HTMLElement>): void
    $$toggleSubMenuByClick(e: HTMLElementEvent<HTMLElement>): void
    $$toggleSubMenuByKeyDown(e: HTMLElementEvent<HTMLElement>): void
    $$toggleItemList(e: HTMLElementEvent<HTMLElement>): void
    $$closeItemList(e: HTMLElementEvent<HTMLElement>): void
    $$openItemList(e: HTMLElementEvent<HTMLElement>): void
    $$toggleMenu(): void
    $$toggleModalMenu(): void
    $$getNo(kijiId: string): number
    $$setNo(no: number): Promise<void>
    $$zeroPadding(num: number, length: number): string
    $$getKijiTitle(
      list: DangoResponse.searchKijiListsIF | DangoResponse.detailKijiListsIF,
      flg: number
    ): string
    $$getKijiBody(list: DangoResponse.detailKijiListsIF): string
    $$getChiezoTitle(
      list:
        | DangoResponse.searchChiezoListsIF
        | DangoResponse.detailChiezoListsIF,
      flg: number
    ): string
    $$getChiezoBody(list: DangoResponse.detailChiezoListsIF): string
    $$getChiezoField(
      list:
        | DangoResponse.searchChiezoListsIF
        | DangoResponse.detailChiezoListsIF
    ): string
    $$getEnglishTitle(list: DangoResponse.searchEnglishListsIF): string
    $$getEnglishTitle2(list: DangoResponse.detailEnglishListsIF): string
    $$getEnglishLink(
      list: DangoResponse.detailEnglishListsIF
    ): [string, string][]
    $$getEnglishBody(
      list: DangoResponse.detailEnglishListsIF,
      bodyName: string
    ): string
    $$getEnglishMedia(
      list: DangoResponse.detailEnglishListsIF
    ): DangoResponse.detailEnglishMedia[]
    $$noTouchMoveOnImage(): void
    $$zoomImage(e: HTMLElementEvent<HTMLElement>): void
    $$removeCssHover(): void
    $$getUnixTime(): number
    $$getDataFromLocalStorage(key: string): string
    $$openNewWindow(path: string, target?: string): void
    $$setData(): void
    $$openStaticPage(key: string, locale: string): void
    $$setSelection(): boolean
    $$closeToolBox(): void
    $$openToolBox(e: any): Promise<void>
    $$registerToolBox(): void
    $$searchBySelectionKeyword(name: string): void
    $$addQuote(): Promise<void>
    $$formatQuote(
      updatetime: string,
      text: string,
      list: any,
      title: string
    ): void
    $$copyQuote(): void
    $$localizePresearch(srchInfo: any): any
    $$addPresearch(): Promise<void>
    $$addFavoriteKiji(): Promise<void>
    $$updateFavoriteKijiMemo(
      uuid: string,
      kijiId: string,
      i: number
    ): Promise<void>
    $$addNotePlus(): Promise<boolean>
    $$getNotePlus(): Promise<void>
    $$updateNotePlus(): Promise<void>
    $$addNotePlus2(): Promise<boolean>
    $$getNotePlus2(): Promise<void>
    $$updateNotePlus2(): Promise<void>
    $$getDate(datetime: string, format: string): string
    $$getInitialValue(key: string): any
    $$setLoginPagePath(): void
    $$unifyChar(str: string): string
  }
}

/**
 * @type {number} 画像の拡大・縮小サイズ
 */
const imageZoomSize: number = 100

/**
 * 正規表現のメタ文字をエスケープ
 * @param {string} str エスケープさせたい文字列
 * @return {string} エスケープされた文字列を返す
 */
const regExpEscape = (str: string): string => {
  return str.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&')
}

/**
 * ユーザー情報を取得する
 * @params {keyof DangoResponse.loginInfo} key
 * @return {number|string|boolean}
 */
Vue.prototype.$$getLoginInfo = function (
  key: keyof DangoResponse.loginInfo
): number | string | boolean {
  return getLoginInfo(key)
}

// mainコンポーネントのビューインスタンス
let mainVueInstance = {}

/**
 * mainコンポーネントのVueインスタンスをセットする
 * @return {void}
 */
Vue.prototype.$$setMainVueInstance = function (): void {
  mainVueInstance = this
}

/**
 * mainコンポーネントのVueインスタンスを取得する
 * @return {any}
 */
Vue.prototype.$$getMainVueInstance = function (): any {
  return mainVueInstance
}

/**
 * マイフォルダ情報を初期化する
 * @return {void}
 */
Vue.prototype.$$initMyFolderInfo = function (): void {
  initMyFolderInfo()
}

/**
 * マイフォルダ情報を取得する
 * @params {keyof MyFolder.detailIF} key1
 * @params {keyof MyFolder.quoteJsonIF | keyof MyFolder.presearchJsonIF | keyof MyFolder.favoriteKijiJsonIF | keyof MyFolder.notePlusJsonIF | ''} key2
 * @return {any}
 */
Vue.prototype.$$getMyFolderInfo = function (
  key1: keyof MyFolder.detailIF,
  key2:
    | keyof MyFolder.quoteJsonIF
    | keyof MyFolder.presearchJsonIF
    | keyof MyFolder.favoriteKijiJsonIF
    | keyof MyFolder.notePlusJsonIF
    | '' = ''
): any {
  return getMyFolderInfo(key1, key2)
}

/**
 * メンテナンス中か確認する
 * @params {string} path
 * @return {boolean}
 */
Vue.prototype.$$checkMaintenance = function (path: string): boolean {
  return checkMaintenance(path)
}

/**
 * ページのアクセス権を確認する
 * @params {string} path
 * @return {boolean}
 */
Vue.prototype.$$checkAccessRights = function (path: string): boolean {
  return checkAccessRights(path)
}

/**
 * サービスの権限を確認する
 * @params {string} key
 * @return {boolean}
 */
Vue.prototype.$$checkSvData = function (key: string): boolean {
  return checkSvData(key)
}

/**
 * ローディング表示フラグを変更する
 * @return {void}
 */
let loadingFlg = 1
Vue.prototype.$$changeLoadingFlg = function (flg: number = 1): void {
  console.log('flg', flg)
  if (flg === 0 || flg === 1) {
    loadingFlg = flg
  }
}

/**
 * ローディングを表示する
 * @return {void}
 */
Vue.prototype.$$showLoading = function (): void {
  if (!loadingFlg) return
  document.body.classList.add('loading')
  const el = document.getElementById('loading')
  if (el) {
    el.style.display = ''
  }
}

/**
 * ローディングを隠す
 * @return {void}
 */
Vue.prototype.$$hideLoading = function (): void {
  const el = document.getElementById('loading')
  if (el) {
    el.style.display = 'none'
  }
  document.body.classList.remove('loading')
}

/**
 * ページ初期化処理
 * @params {string[]} classNames
 * @return {void}
 */
Vue.prototype.$$initializePage = function (classNames: string[]): void {
  // mainコンポーネントのVueインスタンスをセットする
  this.$$setMainVueInstance()

  // クラスを追加
  document.body.classList.forEach((className) =>
    document.body.classList.remove(className)
  )
  classNames.forEach((className) => document.body.classList.add(className))

  let locale = 'ja'
  if (this.$route.path.match(/^\/english\//)) {
    locale = 'en'
  }
  this.$nuxt.$emit('updateLocale', locale)

  // エラーメッセージは日本語
  this.$$setValidationLocale('ja')

  // ローディングを隠す
  this.$$hideLoading()

  if (!classNames.includes('login')) {
    const el = document.getElementById('menu_simplebar')
    if (el) {
      const simplebar = new SimpleBar(el)
      console.log(simplebar)
    }
  }
}

/**
 * バリデーションエラーがあればエラーメッセージを返す
 * @return {Promise<string>}
 */
Vue.prototype.$$validate = async function (): Promise<string> {
  const isValid = await (this.$refs.form as any).validate()
  let errorMessage: string = ''
  if (!isValid) {
    const errors = (this.$refs.form as any).errors
    Object.keys(errors).forEach((key: string) => {
      if (errorMessage !== '') {
        errorMessage += '\n'
      }
      errorMessage += errors[key].join('\n')
    })
  }
  return errorMessage
}

/**
 * キーワードをリセット
 * @return {void}
 */
Vue.prototype.$$resetKeyword = function (): void {
  this.srchInfo[0].keyword = ''
  const keyword: HTMLInputElement = this.$refs.keyword as HTMLInputElement
  keyword.focus()
}

/**
 * キーワードに単語を追加
 * @params {string} word
 * @return {void}
 */
Vue.prototype.$$addWordToKeyword = function (word: string): void {
  if (
    word !== this.$$const.FORM.operator.AND &&
    word !== this.$$const.FORM.operator.OR &&
    word !== this.$$const.FORM.operator.NOT
  ) {
    if (
      this.srchInfo[0].keyword === '' ||
      this.srchInfo[0].keyword.match(/\($/)
    ) {
      word = word.trim()
    }
  }
  this.srchInfo[0].keyword += word
  const keyword: HTMLInputElement = this.$refs.keyword as HTMLInputElement
  keyword.focus()
}

/**
 * 面名に単語を追加
 * @params {string[]} words
 * @return {void}
 */
Vue.prototype.$$addWordToMenName = function (words: string[]): void {
  for (const word of words) {
    if (this.srchInfo[0].menName !== '') {
      this.srchInfo[0].menName += ' ' + this.$$const.FORM.operator.OR + ' '
    }
    this.srchInfo[0].menName += word
  }
}

/**
 * 年から和暦を取得する
 * @params {number} year
 * @params {number} type
 * @return {string} wareki
 */
Vue.prototype.$$getWarekiFromYear = function (
  year: number,
  type: number = 1
): string {
  let wareki = ''
  const eraInfo: (string | number)[][] = [
    ['明', '明治', 1868, 1912],
    ['大', '大正', 1912, 1926],
    ['昭', '昭和', 1926, 1989],
    ['平', '平成', 1989, 2019],
  ]
  let era: string = ''
  for (const info of eraInfo) {
    if (type) {
      era = String(info[0])
    } else {
      era = String(info[1])
    }
    const startYear = Number(info[2])
    const endYear = Number(info[3])
    if (startYear <= year && year <= endYear) {
      if (year === startYear) {
        if (wareki !== '') {
          wareki += '/'
        }
        wareki += era + '元'
      } else {
        const y: number = year - startYear + 1
        wareki += era + y
      }
    }
  }
  return wareki
}

/**
 * 日付プルダウンのオプションを取得
 * @params {string} type year/month/day/conj
 * @return {string[][]}
 */
Vue.prototype.$$getDateOption = function (type: string): string[][] {
  const options: string[][] = []
  if (type === 'year_kiji') {
    options.push(['', ''])
    let c = 1984
    if (this.$$getLoginInfo('SV_PERIOD') !== 0) {
      c = dayjs().year() - this.$$getLoginInfo('SV_PERIOD')
    }
    for (let y = dayjs().year(); y >= c; y--) {
      options.push([String(y), String(y)])
    }
  } else if (type === 'month') {
    options.push(['', ''])
    for (let m = 1; m <= 12; m++) {
      options.push([String(m), String(m)])
    }
  } else if (type === 'day') {
    options.push(['', ''])
    options.push([
      this.$$const.FORM.daySeason.EARLY,
      this.$t('daySeasonParamLabel.EARLY'),
    ])
    options.push([
      this.$$const.FORM.daySeason.MID,
      this.$t('daySeasonParamLabel.MID'),
    ])
    options.push([
      this.$$const.FORM.daySeason.LATE,
      this.$t('daySeasonParamLabel.LATE'),
    ])
    for (let d = 1; d <= 31; d++) {
      options.push([String(d), String(d)])
    }
  } else if (type === 'conj') {
    options.push([
      this.$$const.FORM.hakkouDateConjunction.FROM,
      this.$t('hakkouDateConjunctionParamLabel.FROM'),
    ])
    options.push([
      this.$$const.FORM.hakkouDateConjunction.ONLY,
      this.$t('hakkouDateConjunctionParamLabel.ONLY'),
    ])
    options.push([
      this.$$const.FORM.hakkouDateConjunction.UNTIL,
      this.$t('hakkouDateConjunctionParamLabel.UNTIL'),
    ])
  }
  return options
}

/**
 * 日付をセット
 * @return {void}
 */
Vue.prototype.$$setDate = function (): void {
  const s = this.srchInfo[0]
  if (s.srchTerm !== undefined) {
    s.srchTerm = this.$$const.API.srchTerm.FROM_TO
  }
  s.hakkouDateF = ''
  s.hakkouDateT = ''
  const date = [
    { key: 'hakkouDateF', y: '', m: '', d: '' },
    { key: 'hakkouDateT', y: '', m: '', d: '' },
  ]
  if (
    s.hakkouDateConjunction === this.$$const.FORM.hakkouDateConjunction.FROM
  ) {
    date[0].y = s.hakkouDateF_y
    date[0].m = s.hakkouDateF_m
    date[0].d = s.hakkouDateF_d
    date[1].y = s.hakkouDateT_y
    date[1].m = s.hakkouDateT_m
    date[1].d = s.hakkouDateT_d
  } else if (
    s.hakkouDateConjunction === this.$$const.FORM.hakkouDateConjunction.ONLY
  ) {
    date[0].y = s.hakkouDateF_y
    date[0].m = s.hakkouDateF_m
    date[0].d = s.hakkouDateF_d
    date[1].y = date[0].y
    date[1].m = date[0].m
    date[1].d = date[0].d
  } else if (
    s.hakkouDateConjunction === this.$$const.FORM.hakkouDateConjunction.UNTIL
  ) {
    date[1].y = s.hakkouDateF_y
    date[1].m = s.hakkouDateF_m
    date[1].d = s.hakkouDateF_d
  }

  for (const obj of date) {
    const key = obj.key
    const y = obj.y
    const m = obj.m
    let d = obj.d
    console.log(key + ':y:' + y)
    console.log(key + ':m:' + m)
    console.log(key + ':d:' + d)
    // 年、月、日の指定がなければ次へ
    if (y === '' && m === '' && d === '') {
      continue
    }

    // 年が空で月、日の指定をする事はできない
    if (y === '') {
      s[key] = 'Invalid date'
    } else if (m !== '') {
      // 「年：空でない、月：空でない、日：空でない、期間：FROM」
      if (d !== '' && key === 'hakkouDateF') {
        if (d === this.$$const.FORM.daySeason.EARLY) {
          d = '1'
        } else if (d === this.$$const.FORM.daySeason.MID) {
          d = '10'
        } else if (d === this.$$const.FORM.daySeason.LATE) {
          d = '20'
        }
        // 「年：空でない、月：空でない、日：空でない、期間：TO」
      } else if (d !== '' && key === 'hakkouDateT') {
        if (d === this.$$const.FORM.daySeason.EARLY) {
          d = '10'
        } else if (d === this.$$const.FORM.daySeason.MID) {
          d = '20'
        } else if (d === this.$$const.FORM.daySeason.LATE) {
          d = String(dayjs(y + '-' + m, 'YYYY-MM').daysInMonth())
        }
        // 「年：空でない、月：空でない、日：空、期間：FROM」
      } else if (d === '' && key === 'hakkouDateF') {
        d = '1'
        // 「年：空でない、月：空でない、日：空、期間：TO」
      } else if (d === '' && key === 'hakkouDateT') {
        d = String(dayjs(y + '-' + m, 'YYYY-MM').daysInMonth())
      }
      const date = dayjs(y + '-' + m + '-' + d, 'YYYY-MM-DD').format('YYYYMMDD')
      if (date === sprintf('%04d%02d%02d', y, m, d)) {
        s[key] = date
      } else {
        s[key] = 'Invalid date'
      }
    } else if (m === '') {
      // 「年：空でない、月：空、日：空でない」は指定不可
      if (d !== '') {
        s[key] = 'Invalid date'

        // 「年：空でない、月：空、日：空、期間：FROM」
      } else if (d === '' && key === 'hakkouDateF') {
        s[key] = y + '0101'

        // 「年：空でない、月：空、日：空、期間：FROM」
      } else if (d === '' && key === 'hakkouDateT') {
        s[key] = y + '1231'
      }
    }
  }
  if (s.hakkouDateF === 'Invalid date' && s.hakkouDateT === 'Invalid date') {
    s.hakkouDateT = ''
  }

  // 地域面・外地版の場合、空のFROM/TOを埋める
  if (s.srchId === 'S' && s.menshuAName !== 'H') {
    let fromY = ''
    let toY = ''
    if (s.menshuAName === 'L') {
      fromY = '1900'
      toY = '1999'
    } else {
      fromY = '1935'
      toY = '1945'
    }
    if (s.hakkouDateF === '' && s.hakkouDateT === '') {
      s.hakkouDateF = ''
      s.hakkouDateT = ''
    } else {
      if (s.hakkouDateF === '') {
        s.hakkouDateF = fromY + '0101'
      }
      if (s.hakkouDateT === '') {
        s.hakkouDateT = toY + '1231'
      }
    }
  }
  console.log('hakkouDateF:' + s.hakkouDateF)
  console.log('hakkouDateT:' + s.hakkouDateT)
}

/**
 * 日付をリセット
 * @return {void}
 */
Vue.prototype.$$resetDate = function (): void {
  const s = this.srchInfo[0]
  if (s.srchTerm !== 'FT') {
    s.hakkouDateF = ''
    s.hakkouDateT = ''
    s.hakkouDateF_y = ''
    s.hakkouDateF_m = ''
    s.hakkouDateF_d = ''
    s.hakkouDateConjunction = this.$$const.FORM.hakkouDateConjunction.FROM
    s.hakkouDateT_y = ''
    s.hakkouDateT_m = ''
    s.hakkouDateT_d = ''
  }
}

/**
 * テキストボックスを更新する
 * @params {string} name
 * @return {void}
 */
Vue.prototype.$$updateTextBox = function (name: string): void {
  this.srchInfo[0][name] = this.listBox[name].join(' OR ')
}

/**
 * リストボックス内のチェックボックスを更新する
 * @params {string} name
 * @params {number} i
 * @params {number} gflg
 * @return {void}
 */
Vue.prototype.$$updateListBox = function (
  name: string,
  i: number,
  gflg: number
): void {
  if (gflg) {
    let checked = false
    if (
      this.listBox[name + 'Group'].find((n: number) => n === i) !== undefined
    ) {
      checked = true
    }
    for (const el of this.$el.querySelectorAll(
      '[id*="listBox.' + name + i + '_"]'
    )) {
      if (checked) {
        if (
          this.listBox[name].find((v: string) => v === el.value) === undefined
        ) {
          this.listBox[name].push(el.value)
        }
      } else {
        this.listBox[name] = this.listBox[name].filter(
          (v: string) => v !== el.value
        )
      }
    }
  }

  this.srchInfo[0][name] = this.listBox[name].join(' OR ')

  const arr = []
  for (const el of this.$el.querySelectorAll(
    '[id*="listBox.' + name + 'Group"]'
  )) {
    const i = el.value
    let flg = true
    for (const el2 of this.$el.querySelectorAll(
      '[id*="listBox.' + name + i + '_"]'
    )) {
      if (
        this.listBox[name].find((v: string) => v === el2.value) === undefined
      ) {
        flg = false
      }
    }
    if (flg === true) {
      arr.push(i)
    }
  }
  this.listBox[name + 'Group'] = arr
}

// polyfill
if (!Element.prototype.matches) {
  Element.prototype.matches =
    Element.prototype.msMatchesSelector ||
    Element.prototype.webkitMatchesSelector
}

if (!Element.prototype.closest) {
  Element.prototype.closest = function (s: any) {
    let el = this
    do {
      if (el.matches(s)) return el
      el = (el.parentElement || el.parentNode) as Element
    } while (el !== null && el.nodeType === 1)
    return null
  }
}

/**
 * リストボックスの選択を全解除する
 * @params {string} name
 * @return {void}
 */
Vue.prototype.$$clearListBox = function (name: string): void {
  this.listBox[name] = []
  this.$$updateListBox(name)
}

/**
 * 記事IDのチェックボックスを全て選択する
 * @return {void}
 */
Vue.prototype.$$selectAllKijiIdBox = function (): void {
  const kijiIdBox = []
  for (const el of this.$el.querySelectorAll('.md-topic-list__checkbox')) {
    kijiIdBox.push(el.value.split(','))
  }
  this.kijiIdBox = kijiIdBox
}

/**
 * 記事IDのチェックボックスを全てクリアする
 * @return {void}
 */
Vue.prototype.$$clearAllKijiIdBox = function (): void {
  this.kijiIdBox = []
}

/**
 * ページ上部にスクロールする
 * @params {HTMLElementEvent<HTMLElement>} e
 * @return {void}
 */
Vue.prototype.$$scrollToTop = function (
  e: HTMLElementEvent<HTMLElement>
): void {
  const t = e.target
  const scrlTarget = t.closest('.l-main-box__item')
  if (!scrlTarget) {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  } else {
    scrlTarget.scrollTop = 0
  }
}

/**
 * スクロール位置を調整する
 * @params {HTMLElementEvent<HTMLElement>} e
 * @return {void}
 */
Vue.prototype.$$adjustScrollPosition = function (
  e: HTMLElementEvent<HTMLElement>
): void {
  console.log('adjustScrollPosition')
  e.preventDefault()
  const t = e.target
  const id = t.getAttribute('link')
  if (!id) {
    return
  }
  const el = document.getElementById(id)
  if (!el) {
    return
  }
  const rect = el.getBoundingClientRect().top
  const offset = window.pageYOffset
  const gap =
    window.innerWidth <= parent.$nuxt.$$const.DEVICE.MOBILE_WIDTH ? 110 : 210
  window.scrollTo(0, rect + offset - gap)
}

/**
 * タブを切り替える
 * @params {HTMLElementEvent<HTMLElement>} e
 * @return {void}
 */
Vue.prototype.$$switchTab = function (e: HTMLElementEvent<HTMLElement>): void {
  const t = e.target
  const targetId = t.getAttribute('id')
  const p = t.parentNode as HTMLElement
  if (!p) {
    return
  }
  const pp = p.parentNode as HTMLElement
  if (!pp) {
    return
  }
  const ppn = pp.nextElementSibling
  if (!ppn) {
    return
  }
  const li = p.children
  const panels = ppn.children
  for (let i = 0; i < li.length; i++) {
    const id = li[i].getAttribute('id')
    li[i].setAttribute('aria-selected', id === targetId ? 'true' : 'false')
    panels[i].setAttribute('aria-hidden', id === targetId ? 'false' : 'true')
  }
}

/**
 * クリックしてサブメニュー表示を切り替える
 * @params {HTMLElementEvent<HTMLElement>} e
 * @return {void}
 */
Vue.prototype.$$toggleSubMenuByClick = function (
  e: HTMLElementEvent<HTMLElement>
): void {
  e.target.setAttribute(
    'aria-pressed',
    e.target.getAttribute('aria-pressed') === 'true' ? 'false' : 'true'
  )
}

/**
 * キーダウンしてサブメニュー表示を切り替える
 * @params {HTMLElementEvent<HTMLElement>} e
 * @return {void}
 */
Vue.prototype.$$toggleSubMenuByKeyDown = function (
  e: HTMLElementEvent<HTMLElement>
): void {
  // Space や Enter、Spacebar（IE11）が押されたかどうかを確認
  if (e.key === ' ' || e.key === 'Enter' || e.key === 'Spacebar') {
    // デフォルトのアクションを防止して Space が押されたときにスクロールするのを止める
    e.preventDefault()
    e.target.setAttribute(
      'aria-pressed',
      e.target.getAttribute('aria-pressed') === 'true' ? 'false' : 'true'
    )
  }
}

/**
 * アイテム一覧表示を切り替える
 * @params {HTMLElementEvent<Element>} e
 * @return {void}
 */
Vue.prototype.$$toggleItemList = function (
  e: HTMLElementEvent<HTMLElement>
): void {
  const btn = e.target
  const id = btn.getAttribute('aria-controls')
  if (!id) {
    return
  }
  const t = document.getElementById(id)
  if (!t) {
    return
  }
  if (!simplebar || btn.getAttribute('aria-selected') === 'false') {
    const el = document.getElementById('simplebar')
    if (el) {
      simplebar = new SimpleBar(el, { autoHide: false })
    }
  } else {
    simplebar.unMount()
  }
  btn.setAttribute(
    'aria-selected',
    btn.getAttribute('aria-selected') === 'true' ? 'false' : 'true'
  )
  t.setAttribute(
    'aria-hidden',
    t.getAttribute('aria-hidden') === 'true' ? 'false' : 'true'
  )
}

/**
 * アイテム一覧表示を表示する
 * @params {HTMLElementEvent<HTMLElement>} e
 * @return {void}
 */
Vue.prototype.$$openItemList = function (
  e: HTMLElementEvent<HTMLElement>
): void {
  const btn = e.target
  const id = btn.getAttribute('aria-controls')
  if (!id) {
    return
  }
  const t = document.getElementById(id)
  if (!t) {
    return
  }
  btn.setAttribute('aria-selected', 'true')
  t.setAttribute('aria-hidden', 'false')
}

/**
 * アイテム一覧表示を非表示にする
 * @params {HTMLElementEvent<HTMLElement>} e
 * @return {void}
 */
Vue.prototype.$$closeItemList = function (
  e: HTMLElementEvent<HTMLElement>
): void {
  const t = e.target.closest('[role="tabpanel"]')
  if (!t) {
    return
  }
  const id = t.getAttribute('aria-labelledby')
  if (!id) {
    return
  }
  const btn = document.getElementById(id)
  if (!btn) {
    return
  }
  if (simplebar) {
    simplebar.unMount()
  }
  btn.setAttribute('aria-selected', 'false')
  t.setAttribute('aria-hidden', 'true')
}

/**
 * メニューの表示を切り替える
 * @return {void}
 */
Vue.prototype.$$toggleMenu = function (): void {
  const btn = document.getElementById('header-menu-more')
  if (!btn) {
    return
  }
  const parent = document.getElementById('header-menu')
  if (!parent) {
    return
  }
  btn.setAttribute(
    'aria-selected',
    btn.getAttribute('aria-selected') === 'true' ? 'false' : 'true'
  )
  parent.setAttribute(
    'aria-selected',
    parent.getAttribute('aria-selected') === 'true' ? 'false' : 'true'
  )
}

/**
 * モーダルメニューの表示を切り替える
 * @return {void}
 */
Vue.prototype.$$toggleModalMenu = function (): void {
  const btn = document.getElementById('modal-header-menu-more')
  if (!btn) {
    return
  }
  const parent = document.getElementById('modal-header-menu')
  if (!parent) {
    return
  }
  btn.setAttribute(
    'aria-selected',
    btn.getAttribute('aria-selected') === 'true' ? 'false' : 'true'
  )
  parent.setAttribute(
    'aria-selected',
    parent.getAttribute('aria-selected') === 'true' ? 'false' : 'true'
  )
}

/**
 * Noを取得する
 * @params {string} kijiId
 * @return {number}
 */
Vue.prototype.$$getNo = function (kijiId: string): number {
  let no = 0
  for (const item of this.detailApi.itemInfo) {
    if (item[0] === kijiId) {
      no = item[1]
      break
    }
  }
  return no
}

/**
 * Noをセットする
 * @params {number} no
 * @return {Promise<void>}
 */
Vue.prototype.$$setNo = async function (no: number): Promise<void> {
  let start = this.searchApi.start
  if (!start) {
    return
  }
  let end = this.searchApi.end
  if (!end) {
    return
  }
  if (no === 1) {
    if (start !== 1) {
      await this.$$reSearch(1)
    }
  } else if (no < start) {
    await this.$$reSearch(this.searchApi.page - 1)
  } else if (no > end) {
    await this.$$reSearch(this.searchApi.page + 1)
  }
  start = this.searchApi.start
  if (!start) {
    return
  }
  end = this.searchApi.end
  if (!end) {
    return
  }
  const i: number = no - start
  if (start <= no && no <= end) {
    const resultInfo = this.searchApi.result[0].resultInfo
    if (!resultInfo) {
      return
    }
    if (this.detailApi.srchId === this.$$const.API.srchId.KIJI) {
      this.detailApi.itemInfo = [
        [resultInfo.lists[i].KijiId, this.searchApi.start + i],
      ]
    } else if (this.detailApi.srchId === this.$$const.API.srchId.CHIEZO) {
      this.detailApi.itemInfo = [
        [resultInfo.lists[i].C_ItemNo, this.searchApi.start + i],
      ]
    } else if (this.detailApi.srchId === this.$$const.API.srchId.ENGLISH) {
      this.detailApi.itemInfo = [
        [resultInfo.item[i].itemid, this.searchApi.start + i],
      ]
    }
  }
}

/**
 * ゼロ埋め
 * @params {number} num
 * @params {number} length
 * @return {string}
 */
Vue.prototype.$$zeroPadding = function (num: number, length: number): string {
  if (String(num).length >= length) {
    return String(num)
  } else {
    return ('0000000000' + num).slice(-length)
  }
}

/**
 * 記事検索結果の見出しを取得する
 * @params {DangoResponse.searchKijiListsIF | DangoResponse.detailKijiListsIF} list
 * @params {number} flg
 * @return {string}
 */
Vue.prototype.$$getKijiTitle = function (
  list: DangoResponse.searchKijiListsIF | DangoResponse.detailKijiListsIF,
  flg: number = 1
): string {
  let title = ''

  // 全て可 or 切り抜きのみ不可 or 書誌・見出しのみ可
  if (
    list.GaihanCtl === this.$$const.API_RESPONSE.GaihanCtl.ALL_OK ||
    list.GaihanCtl === this.$$const.API_RESPONSE.GaihanCtl.KIRINUKI_NG ||
    list.GaihanCtl === this.$$const.API_RESPONSE.GaihanCtl.SHOSHI_MIDASHI_OK
  ) {
    if (list.Midashi !== '') {
      // 改行コード変換、ハイライト表示
      title = list.Midashi.replace(/\n/g, '<br />')
      if (flg) {
        const keywords = this.detailApi.keywords
        if (keywords.length > 0) {
          const pattern =
            '(' +
            keywords.map((str: string) => regExpEscape(str)).join('|') +
            ')'
          const regexp = new RegExp(pattern, 'g')
          title = title.replace(regexp, '<span class="highlight">$1</span>')
        }
      }
    } else {
      title = '[見出しがありません]'
    }
    // 全て不可
  } else {
    title = '※著作権などの関係で見出しを表示できません。<BR>\n'
  }
  return title
}

/**
 * 記事検索結果の本文を取得する
 * @params {DangoResponse.detailKijiListsIF} list
 * @return {string}
 */
Vue.prototype.$$getKijiBody = function (
  list: DangoResponse.detailKijiListsIF
): string {
  let body = ''

  // 全て可 or 切り抜きのみ不可
  if (
    list.GaihanCtl === this.$$const.API_RESPONSE.GaihanCtl.ALL_OK ||
    list.GaihanCtl === this.$$const.API_RESPONSE.GaihanCtl.KIRINUKI_NG
  ) {
    const keywords = this.detailApi.keywords
    const pattern =
      '(' + keywords.map((str: string) => regExpEscape(str)).join('|') + ')'
    const regexp = new RegExp(pattern, 'g')

    // 記事本分
    if (list.Kiji !== '') {
      // 改行コード変換
      body = list.Kiji.replace(/\s+$/, '').replace(/\n/g, '<br />')
      // ハイライト表示
      if (this.detailApi.keywords.length > 0) {
        body = body.replace(regexp, '<span class="highlight">$1</span>')
      }
    }

    // 補助キーワード
    if (list.AssistKW !== '') {
      // 改行コード変換、ハイライト表示
      let assistKw = list.AssistKW.replace(/\s+$/, '').replace(/\n/g, '<br />')
      // ハイライト表示
      if (this.detailApi.keywords.length > 0) {
        assistKw = assistKw.replace(regexp, '<span class="highlight">$1</span>')
      }
      let assistKwLabel = '補助キーワード：'
      if (this.$i18n.locale === 'en') {
        assistKwLabel = 'Auxiliary keywords：'
      }
      body += '<br />' + assistKwLabel + assistKw + '<br />'
    }
    // 書誌・見出しのみ可 or 全て不可
  } else {
    body = '※著作権などの関係で本文を表示できません。<BR>\n'
  }
  return body
}

/**
 * 知恵蔵検索結果の見出しを取得する
 * @params {DangoResponse.searchChiezoListsIF | DangoResponse.detailChiezoListsIF} list
 * @params {number} flg
 * @return {string}
 */
Vue.prototype.$$getChiezoTitle = function (
  list: DangoResponse.searchChiezoListsIF | DangoResponse.detailChiezoListsIF,
  flg: number = 1
): string {
  let title = ''

  if (list.C_Midashi !== '') {
    title = list.C_Midashi
    if (list.C_MidashiAlph !== '') {
      title += '（' + list.C_MidashiAlph + '）'
    }

    // ハイライト
    if (flg) {
      const keywords = this.detailApi.keywords
      if (keywords.length > 0) {
        const pattern =
          '(' + keywords.map((str: string) => regExpEscape(str)).join('|') + ')'
        const regexp = new RegExp(pattern, 'g')
        title = title.replace(regexp, '<span class="highlight">$1</span>')
      }
    }
  } else {
    title = '[見出しがありません]'
  }
  return title
}

/**
 * 知恵蔵検索結果の本文を取得する
 * @params {DangoResponse.detailChiezoListsIF} list
 * @return {string}
 */
Vue.prototype.$$getChiezoBody = function (
  list: DangoResponse.detailChiezoListsIF
): string {
  let body = ''

  // 記事本分
  if (list.C_Kaisetsu !== '') {
    // 改行コード変換
    body = list.C_Kaisetsu.replace(/\s+$/, '').replace(/\n/g, '<br />')
    // ハイライト表示
    const keywords = this.detailApi.keywords
    if (keywords.length > 0) {
      const pattern =
        '(' + keywords.map((str: string) => regExpEscape(str)).join('|') + ')'
      const regexp = new RegExp(pattern, 'g')
      body = body.replace(regexp, '<span class="highlight">$1</span>')
    }
  }
  return body
}

/**
 * 知恵蔵検索結果の分野を取得する
 * @params {DangoResponse.searchChiezoListsIF | DangoResponse.detailChiezoListsIF} list
 * @return {string}
 */
Vue.prototype.$$getChiezoField = function (
  list: DangoResponse.searchChiezoListsIF | DangoResponse.detailChiezoListsIF
): string {
  let field = ''

  if (list.C_BunyaL) {
    field = list.C_BunyaL
    if (list.C_BunyaS) {
      field += '-' + list.C_BunyaS
    }
  }
  return field
}

/**
 * 英文ニュース検索結果の見出しを取得する
 * @params {DangoResponse.searchEnglishListsIF} list
 * @return {string}
 */
Vue.prototype.$$getEnglishTitle = function (
  list: DangoResponse.searchEnglishListsIF
): string {
  let title = ''

  if (list.headline !== '') {
    title = list.headline.replace('\n', '<br />')
  } else {
    title = '[no english title]'
  }
  return title
}

/**
 * 英文ニュース検索結果の見出しを取得する
 * @params {DangoResponse.detailEnglishListsIF} list
 * @return {string}
 */
Vue.prototype.$$getEnglishTitle2 = function (
  list: DangoResponse.detailEnglishListsIF
): string {
  let title = ''

  if (list.headline !== '') {
    title = list.headline.replace('\n', '<br />')
    title = title.replace(
      /<mark>(.*?)<\/mark>/g,
      '<span class="highlight">$1</span>'
    )
  } else {
    title = '[no english title]'
  }
  title = '<h2>' + title + '</h2>'

  // 署名
  if (list.byline !== undefined && list.byline !== '') {
    title += '<p>' + list.byline + '</p>'
  }

  return title
}

/**
 * 英文ニュース検索結果の本文へのページ内リンクを取得する
 * @params {DangoResponse.detailEnglishListsIF} list
 * @return {{key:string, name:string}[]}
 */
Vue.prototype.$$getEnglishLink = function (
  list: DangoResponse.detailEnglishListsIF
): { key: string; name: string }[] {
  const links = []
  if (list.headline !== '' || list.body !== '') {
    links.push({ key: 'en', name: 'English' })
  }
  if (list.headline_jp !== '' || list.body_jp !== '') {
    links.push({ key: 'jp', name: 'Japanese' })
  }
  if (list.headline_tw !== '' || list.body_tw !== '') {
    links.push({ key: 'tw', name: 'Chinese(traditional)' })
  }
  if (list.headline_cn !== '' || list.body_cn !== '') {
    links.push({ key: 'cn', name: 'Chinese(simplified)' })
  }
  if (list.headline_ko !== '' || list.body_ko !== '') {
    links.push({ key: 'ko', name: 'Korean' })
  }
  if (links.length > 0 && (links.length !== 1 || links[0].key !== 'en')) {
    return links
  } else {
    return []
  }
}

/**
 * 英文ニュース検索結果の本文を取得する
 * @params {DangoResponse.detailEnglishListsIF} list
 * @params {string} bodyName
 * @return {string}
 */
Vue.prototype.$$getEnglishBody = function (
  list: DangoResponse.detailEnglishListsIF,
  bodyName: string = 'body'
): string {
  let body = ''

  if (
    bodyName === 'body' ||
    bodyName === 'body_jp' ||
    bodyName === 'body_tw' ||
    bodyName === 'body_cn' ||
    bodyName === 'body_ko'
  ) {
    const str = list[bodyName]
    if (str !== undefined && str !== '') {
      body = str
    }
    if (bodyName === 'body') {
      if (body !== '') {
        body = body.replace(
          /<mark>(.*?)<\/mark>/g,
          '<span class="highlight">$1</span>'
        )
      } else {
        body = '[no english article]'
      }
    }
  }
  return body
}

/**
 * 英文ニュース検索結果の画像情報を取得する
 * @params {DangoResponse.detailEnglishListsIF} list
 * @return {DangoResponse.detailEnglishMedia[]}
 */
Vue.prototype.$$getEnglishMedia = function (
  list: DangoResponse.detailEnglishListsIF
): DangoResponse.detailEnglishMedia[] {
  const media: DangoResponse.detailEnglishMedia[] = []
  if (list.media !== undefined && list.media.length > 0) {
    for (const i in list.media) {
      if (
        list.media[i].media_value === 'photo-middle' &&
        list.fdata !== undefined &&
        list.fdata[i] !== undefined
      ) {
        list.media[i].fdata = list.fdata[i]
        media.push(list.media[i])
      }
    }
  }
  return media
}

/**
 * eventを無効にする
 * @params {any} e
 * @return {void}
 */
const disableEvent = (e: any): void => {
  e.preventDefault()
}

/**
 * 画像上のtouchmoveを禁止する
 * @return {void}
 */
Vue.prototype.$$noTouchMoveOnImage = function (): void {
  const viewport = document.getElementById('dispCrop')
  if (!viewport) {
    return
  }
  viewport.addEventListener('touchmove', disableEvent, { passive: false })
}

/**
 * 画像を拡大縮小する
 * @params {HTMLElementEvent<HTMLElement>} e
 * @return {void}
 */
Vue.prototype.$$zoomImage = function (e: HTMLElementEvent<HTMLElement>): void {
  const id = e.target.id
  const img = document.getElementById('dispCrop_img') as HTMLImageElement
  if (img == null) {
    return
  }

  // 拡大・縮小ボタンの初回押下時の画像制御
  if (img.classList.contains('zoomed') === false) {
    img.setAttribute('width', img.width + 'px')
    img.style.cssText = 'max-width: none; max-height: none;'
    img.classList.add('zoomed')
  }

  if (id === 'btnEnlargement') {
    img.width += imageZoomSize
  } else if (id === 'btnReduction') {
    if (img.width > imageZoomSize + 50) {
      img.width -= imageZoomSize
    }
  }
}

/**
 * スマホ・タブレットの場合はCSS「:hover」を全て削除する
 * @return {void}
 */
Vue.prototype.$$removeCssHover = function (): void {
  const touch =
    'ontouchstart' in document.documentElement ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0

  if (touch) {
    try {
      // prevent exception on browsers not supporting DOM styleSheets properly
      for (const si in document.styleSheets) {
        const styleSheet = document.styleSheets[si] as CSSStyleSheet
        if (!styleSheet.rules) continue

        for (let ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
          const rule = styleSheet.rules[ri] as CSSStyleRule
          if (!rule.selectorText) continue

          if (rule.selectorText.match(':hover')) {
            styleSheet.deleteRule(ri)
          }
        }
      }
    } catch (ex) {}
  }
}

/**
 * UNIX時間を返す
 * @return {number}
 */
Vue.prototype.$$getUnixTime = function (): number {
  const date = new Date()
  const microsecound = date.getTime()
  return Math.floor(microsecound / 1000)
}

/**
 * ローカルストレージからデータを取得する
 * @params {string} key
 * @return {string}
 */
Vue.prototype.$$getDataFromLocalStorage = function (key: string): string {
  // ローカルストレージからデータを取得する
  let item = window.localStorage.getItem(key)

  // ローカルストレージにデータがあるか
  if (item === null) {
    // セッションストレージからデータを取得する
    item = window.sessionStorage.getItem(key)

    // セッションストレージにデータがなければ404エラー
    if (item === null) {
      try {
        throw new NotFoundError()
      } catch (e) {
        this.$$redirectErrorPage(e)
      }
    }
  } else {
    // セッションストレージにデータを保存する
    window.sessionStorage.setItem(key, item)

    // ローカルストレージのデータを削除する
    window.localStorage.removeItem(key)
  }
  return String(item)
}

/**
 * 別ウインドウを開く
 * @params {string} path
 * @params {string} target
 * @return {void}
 */
const win = {} as any
Vue.prototype.$$openNewWindow = function (
  path: string,
  target: string = '_blank'
): void {
  let val = ''
  if (
    path === '/kiji/list/' ||
    path === '/chiezo/list/' ||
    path === '/english/list/'
  ) {
    val = JSON.stringify([this.srchInfo, this.searchApi])
  } else if (
    path === '/kiji/print_list/' ||
    path === '/chiezo/print_list/' ||
    path === '/english/print_list/'
  ) {
    val = JSON.stringify([this.searchApi, this.kijiIdBox])
  } else if (
    path === '/kiji/detail/' ||
    path === '/kiji/detail_multi/' ||
    path === '/chiezo/detail/' ||
    path === '/chiezo/detail_multi/' ||
    path === '/english/detail/' ||
    path === '/english/detail_multi/'
  ) {
    val = JSON.stringify([this.srchInfo, this.searchApi, this.detailApi])
  } else if (
    path === '/kiji/image/' ||
    path === '/kiji/memo/' ||
    path === '/kiji/sdgs/'
  ) {
    val = JSON.stringify(this.imageInfo)
  } else if (path === '/noteplus/') {
    val = JSON.stringify(this.notePlus.detail)
  }
  if (val !== '') {
    window.localStorage.setItem(path, val)
  }
  if (path === '/noteplus/') {
    const uuid = this.notePlus.detail.uuid
    if (win[uuid] === undefined || win[uuid].closed) {
      win[uuid] = window.open(path + '?' + new Date().getTime(), uuid)
    } else {
      win[uuid].focus()
    }
  } else if (target === '_self') {
    window.open(path, target)
  } else {
    window.open(path + '?' + new Date().getTime(), target)
  }
}

/**
 * データをセット
 * @return {void}
 */
Vue.prototype.$$setData = function (): void {
  const path = this.$route.path
  const item = this.$$getDataFromLocalStorage(path)
  if (
    path === '/kiji/list/' ||
    path === '/chiezo/list/' ||
    path === '/english/list/'
  ) {
    const arr = JSON.parse(item)
    console.log(arr)
    this.srchInfo = arr[0]
    this.searchApi = arr[1]
  } else if (
    path === '/kiji/print_list/' ||
    path === '/chiezo/print_list/' ||
    path === '/english/print_list/'
  ) {
    const arr = JSON.parse(item)
    this.searchApi = arr[0]
    this.kijiIdBox = arr[1]
  } else if (
    path === '/kiji/detail/' ||
    path === '/kiji/detail_multi/' ||
    path === '/chiezo/detail/' ||
    path === '/chiezo/detail_multi/' ||
    path === '/english/detail/' ||
    path === '/english/detail_multi/'
  ) {
    const arr = JSON.parse(item)
    console.log(arr)
    this.srchInfo = arr[0]
    this.searchApi = arr[1]
    this.detailApi = arr[2]
  } else if (
    path === '/kiji/image/' ||
    path === '/kiji/memo/' ||
    path === '/kiji/sdgs/'
  ) {
    this.imageInfo = JSON.parse(item)
  } else if (path === '/noteplus/') {
    this.org = JSON.parse(item)
  }
}

/**
 * 静的ページを開く
 * @params {string} key
 * @params {string} locale
 * @return {void}
 */
Vue.prototype.$$openStaticPage = function (
  key: string,
  locale: string = ''
): void {
  const svSname = String(this.$$getLoginInfo('SV_SNAME'))
  let list = this.$$const.STATIC_PAGE[key]
  if (list !== undefined) {
    const path = list[svSname]
    if (path !== undefined) {
      window.open(path, '_blank')
      return
    }
  }
  let localeKey = this.$i18n.locale
  if (locale !== '') {
    localeKey = locale
  }
  list = this.$$const.STATIC_PAGE[localeKey]
  if (list !== undefined) {
    const path = list[key]
    if (path !== undefined) {
      window.open(path, '_blank')
    }
  }
}

/**
 * 選択文字列をセットする
 * @return {boolean}
 */
Vue.prototype.$$setSelection = function (): boolean {
  // 変数初期化
  this.selecionId1 = ''
  this.selecionId2 = ''
  this.selecionId = ''
  this.selectionStr = ''
  this.selectionKeyword = ''

  // 選択文字列が空かチェックする
  const selection = window.getSelection()
  if (!selection) {
    return false
  }
  if (selection.toString() === '') {
    return false
  }

  // 複数記事の文字列を選択していないかチェックする
  const anchorNode = selection.anchorNode // selectionの開始ノード
  const focusNode = selection.focusNode //  selectionの終了ノード
  console.log(selection)
  if (!anchorNode || !focusNode) {
    return false
  }
  if (!anchorNode.parentElement || !focusNode.parentElement) {
    return false
  }
  const el1 = anchorNode.parentElement.closest('.md-article-card')
  const el2 = focusNode.parentElement.closest('.md-article-card')
  console.log(el1, el2)
  if (!el1 || !el2) {
    return false
  }
  this.selectionId1 = el1.getAttribute('id')
  this.selectionId2 = el2.getAttribute('id')
  if (this.selectionId1 !== this.selectionId2) {
    return false
  }

  // 選択文字列をセット
  this.selectionId = this.selectionId1
  this.selectionStr = selection.toString()
  this.selectionKeyword = this.selectionStr.replace(/\n/g, '')
  console.log(this.selectionId, this.selectionStr)

  return true
}

/**
 * ツールボックスを開く
 * @return {void}
 */
Vue.prototype.$$closeToolBox = function (): void {
  console.log('closeToolBox')
  this.selecionId = ''
  this.selectionStr = ''
  this.selectionKeyword = ''
  const toolbox = document.getElementById('toolbox')
  if (!toolbox) {
    return
  }
  toolbox.style.display = 'none'
  window.getSelection()!.removeAllRanges()
}

/**
 * ツールボックスを開く
 * @params {any} e
 * @return {Promise<void>}
 */
Vue.prototype.$$openToolBox = async function (e: any): Promise<void> {
  const targetId = e.target.getAttribute('id')
  console.log('openToolBox', e.type, targetId)
  if (targetId !== null && targetId.match(/^toolbox-/)) {
    return
  }

  const headers = document.getElementsByTagName('header')
  if (!headers) {
    return
  }
  const header = headers.item(0)
  if (!header) {
    return
  }
  const toolbox = document.getElementById('toolbox')
  if (!toolbox) {
    return
  }

  // Androidなどでイベント発生時に選択文字列がセットされていない場合がある
  // 選択文字列が取得できるか調べるためのリトライ処理
  for (let i = 0; i < 5; ++i) {
    // 選択文字列をセットする
    const res = this.$$setSelection()
    if (!res) {
      // ツールボックスを閉じる
      this.$$closeToolBox()
    } else {
      // ツールボックスを表示する
      let y = 0
      if (e.pageY !== undefined) {
        y = e.pageY
      } else {
        y = e.changedTouches[0].pageY
      }
      const top = y - header.clientHeight
      const left = document.body.clientWidth / 2 - 200
      toolbox.style.top = top + 'px'
      toolbox.style.left = left + 'px'
      toolbox.style.display = ''
    }

    // スリープ
    const sleep = (msec: number) => {
      return new Promise((resolve) => setTimeout(resolve, msec))
    }
    await sleep(100)
  }
}

/**
 * 選択文字列検索ツールボックスを登録する
 * @return {void}
 */
Vue.prototype.$$registerToolBox = function (): void {
  const self = this
  const toolbox = document.getElementById('toolbox')
  if (!toolbox) {
    return
  }
  const appContainer = document.getElementById('appContainer')
  if (!appContainer) {
    return
  }

  // ボタンのclickイベントを登録する
  for (const str of ['kiji', 'chiezo']) {
    const el = document.getElementById('toolbox-' + str)
    if (el) {
      el.addEventListener('click', function () {
        self.$$searchBySelectionKeyword(str)
      })
      el.addEventListener('touchend', function () {
        self.$$searchBySelectionKeyword(str)
      })
    }
  }

  const toolboxQuote = document.getElementById('toolbox-quote')
  if (toolboxQuote) {
    toolboxQuote.addEventListener('click', function () {
      self.$$addQuote()
    })
    toolboxQuote.addEventListener('touchend', function () {
      self.$$addQuote()
    })
  }

  // マイフォルダアイコンクリック時はツールボックスを閉じる
  const myFolderBtn = document.getElementById('myFolderBtn')
  if (myFolderBtn) {
    myFolderBtn.addEventListener('click', function () {
      self.$$closeToolBox()
    })
  }

  // イベントを登録
  appContainer.addEventListener('selectstart', function () {
    self.$$closeToolBox()
  })
  appContainer.addEventListener('mouseup', function (e: any) {
    self.$$openToolBox(e)
  })
  toolbox.addEventListener('mousedown', function (e: any) {
    e.preventDefault()
  })
  if (isSpDevice()) {
    appContainer.addEventListener('touchstart', function (e: any) {
      self.$$openToolBox(e)
    })
    appContainer.addEventListener('touchend', function (e: any) {
      self.$$openToolBox(e)
    })
    toolbox.addEventListener('touchstart', function (e: any) {
      e.preventDefault()
    })
    toolbox.addEventListener('touchend', function (e: any) {
      e.preventDefault()
    })
  }
}

/**
 * 選択文字列で検索する
 * @params {string} name
 * @return {void}
 */
Vue.prototype.$$searchBySelectionKeyword = function (name: string): void {
  console.log('$$searchBySelectionKeyword')
  // 選択文字列をセットする
  const res = this.$$setSelection()
  if (!res) {
    if (this.selectionId1 !== this.selectionId2) {
      alert(this.$t('message.toolBox.overSelectionRange'))
    }
    // ツールボックスを閉じる
    this.$$closeToolBox()
    return
  }

  // メンテナンス中か確認する
  if (this.$$checkMaintenance('/' + name + '/')) {
    alert(this.$t('message.maintenance'))
    return
  }

  this.$router.push({
    name,
    params: { xKeyword: this.selectionKeyword },
  })
}

/**
 * 引用を追加する
 * @return {Promise<void>}
 */
Vue.prototype.$$addQuote = async function (): Promise<void> {
  console.log('$$addQuote')
  // 選択文字列をセットする
  const res = this.$$setSelection()
  if (!res) {
    if (this.selectionId1 !== this.selectionId2) {
      alert(this.$t('message.toolBox.overSelectionRange'))
    }
    // ツールボックスを閉じる
    this.$$closeToolBox()
    return
  }

  // マイフォルダを開いているかチェック
  if (!this.$$getLoginInfo('S_HZNFOLDER')) {
    // メッセージ表示
    this.$nuxt.$emit('setAlertId', 'quoteNotOpen')
    return
  }

  // マイフォルダJSONを取得する
  await this.$$getMyFolderJson(['QUOTE'])

  // 登録上限数を超えないかチェック
  if (
    this.$$getMyFolderInfo('QUOTE', 'detailCount') + 1 >
    this.$$const.MY_FOLDER.QUOTE_LIMIT
  ) {
    // メッセージ表示
    this.$nuxt.$emit('setAlertId', 'quoteIsFull')
    return
  }

  // 詳細情報が存在するかチェック
  let list = {}
  for (const obj of this.detailApi.result.lists) {
    if (obj.KijiId === this.selectionId) {
      list = obj
      break
    }
  }
  if (!list) {
    return
  }

  // 保存情報を整形する
  const json = { detail: [] as MyFolder.quoteDetailIF[] }
  const updatetime = dayjs().format('YYYYMMDDHHmmss')
  json.detail.push({
    uuid: '',
    updatetime,
    kijiId: this.selectionId,
    text: JSON.stringify(this.selectionStr),
  })
  const hznJson = JSON.stringify(json)
  console.log(hznJson)

  // マイフォルダ保存情報を更新する
  const res2 = await this.$$addMyFolderInfo(
    this.$$const.API.kinouId.QUOTE,
    hznJson
  )

  if (res2) {
    // 引用を整形する
    this.$$formatQuote(
      updatetime,
      this.selectionStr,
      list,
      this.$$getKijiTitle(list, 0)
    )

    // メッセージ表示
    this.$nuxt.$emit('setQuote', this.quote)

    // メッセージ表示
    this.$nuxt.$emit('setAlertId', 'addQuote')
  }
}

/**
 * 引用を整形する
 * @params {string} updatetime
 * @params {string} text
 * @params {any} list
 * @params {string} title
 * @return {void}
 */
Vue.prototype.$$formatQuote = function (
  updatetime: string,
  text: string,
  list: any,
  title: string
): void {
  this.quote =
    text +
    '【' +
    list.ShishiName +
    this.$$getDate(list.HakkouDate + '000000', 'YYYY年MM月DD日') +
    list.KanshuName +
    list.Page +
    'ページ「' +
    title +
    '」（「' +
    this.$$getLoginInfo('SV_NAME') +
    '」から' +
    this.$$getDate(updatetime + '000000', 'YYYY年MM月DD日') +
    '情報取得）】'
}

/**
 * 引用をコピーする
 * @return {void}
 */
Vue.prototype.$$copyQuote = function (): void {
  console.log('$$copyQuote')
  const self = this
  navigator.clipboard
    .writeText(this.quote)
    .then(() => {
      alert(self.$t('message.copySuccess'))
    })
    .catch((e) => {
      alert(self.$t('message.copyFailed'))
      console.log(e)
    })
}

/**
 * 検索条件をローカライズする
 * @params {any} srchInfo
 * @return {any}
 */
Vue.prototype.$$localizePresearch = function (srchInfo: any): any {
  const getSrchTerm = (v: string) => {
    const obj = this.$$const.API.srchTerm
    return Object.keys(obj).find((k) => obj[k] === v)
  }
  const getShishiName = (v: string) => {
    const obj = this.$$const.API.shishiName
    return Object.keys(obj).find((k) => obj[k] === v)
  }
  const getKanshuName = (v: string) => {
    const obj = this.$$const.API.kanshuName
    return Object.keys(obj).find((k) => obj[k] === v)
  }
  let dspOrder = this.$t('dspOrderParamLabel.OLDEST_TO_NEWEST')
  if (srchInfo.dspOrder === this.$$const.API.dspOrder.NEWEST_TO_OLDEST) {
    dspOrder = this.$t('dspOrderParamLabel.NEWEST_TO_OLDEST')
  }
  let hakkouDate = ''
  if (srchInfo.srchTerm !== this.$$const.API.srchTerm.FROM_TO) {
    hakkouDate += this.$t(
      'srchTermParamLabel.' + getSrchTerm(srchInfo.srchTerm)
    )
  } else {
    if (srchInfo.hakkouDateF !== '') {
      hakkouDate += this.$$getDate(
        srchInfo.hakkouDateF + '000000',
        'YYYY年MM月DD日'
      )
      hakkouDate += this.$t('fromLabel')
    }
    if (srchInfo.hakkouDateT !== '') {
      hakkouDate += this.$$getDate(
        srchInfo.hakkouDateT + '000000',
        'YYYY年MM月DD日'
      )
      hakkouDate += this.$t('toLabel')
    }
  }
  const shishiNames = []
  for (const v of srchInfo.shishiNames) {
    shishiNames.push(this.$t('shishiNameParamLabel.' + getShishiName(v)))
  }
  if (shishiNames.length === 0) {
    shishiNames.push(this.$t('unspecifiedLabel'))
  }
  const kanshuNames = []
  for (const v of srchInfo.kanshuNames) {
    kanshuNames.push(this.$t('kanshuNameParamLabel.' + getKanshuName(v)))
  }
  if (kanshuNames.length === 0) {
    kanshuNames.push(this.$t('unspecifiedLabel'))
  }
  let menName = srchInfo.menName
  if (srchInfo.menName === '') {
    menName = this.$t('unspecifiedLabel')
  }
  let kirinukiUseReq = this.$t('kirinukiUseReqParamLabel.FALSE')
  if (srchInfo.kirinukiUseReq === this.$$const.API.kirinukiUseReq.TRUE) {
    kirinukiUseReq = this.$t('kirinukiUseReqParamLabel.TRUE')
  }
  return {
    keyword: srchInfo.keyword,
    dspNum: srchInfo.dspNum,
    dspOrder,
    hakkouDate,
    shishiNames,
    kanshuNames,
    menName,
    kirinukiUseReq,
  }
}

/**
 * 検索条件を追加する
 * @return {Promise<void>}
 */
Vue.prototype.$$addPresearch = async function (): Promise<void> {
  console.log('$$addPresearch')

  // マイフォルダを開いているかチェック
  if (!this.$$getLoginInfo('S_HZNFOLDER')) {
    // メッセージ表示
    this.$nuxt.$emit('setAlertId', 'presearchNotOpen')
    return
  }

  // マイフォルダJSONを取得する
  await this.$$getMyFolderJson(['PRESEARCH'])

  // 登録上限数を超えないかチェック
  if (
    this.$$getMyFolderInfo('PRESEARCH', 'detailCount') + 1 >
    this.$$const.MY_FOLDER.PRESEARCH_LIMIT
  ) {
    // メッセージ表示
    this.$nuxt.$emit('setAlertId', 'presearchIsFull')
    return
  }

  // 保存情報を整形する
  const json = { detail: [] as MyFolder.presearchDetailIF[] }
  json.detail.push({
    uuid: '',
    updatetime: dayjs().format('YYYYMMDDHHmmss'),
    srchInfo: JSON.stringify(this.srchInfo),
  })
  const hznJson = JSON.stringify(json)
  console.log(hznJson)

  // マイフォルダ保存情報を更新する
  const res2 = await this.$$addMyFolderInfo(
    this.$$const.API.kinouId.PRESEARCH,
    hznJson
  )

  if (res2) {
    // 検索条件をローカライズする
    this.$nuxt.$emit(
      'setSrchCondition',
      this.$$localizePresearch(this.srchInfo[0])
    )

    // メッセージ表示
    this.$nuxt.$emit('setAlertId', 'addPresearch')
  }
}

/**
 * お気に入り記事を追加する
 * @paramas {string} kijiId
 * @return {Promise<void>}
 */
Vue.prototype.$$addFavoriteKiji = async function (
  kijiId: string = ''
): Promise<void> {
  console.log('$$addFavoriteKiji')
  // バリデーション
  if (kijiId === '' && this.kijiIdBox.length === 0) {
    alert('記事を選択してください。')
    return
  }

  // マイフォルダを開いているかチェック
  if (!this.$$getLoginInfo('S_HZNFOLDER')) {
    // メッセージ表示
    this.$nuxt.$emit('setAlertId', 'favoriteKijiNotOpen')
    return
  }

  // マイフォルダJSONを取得する
  await this.$$getMyFolderJson(['FAVORITE_KIJI'])

  // 登録済みの記事IDを取り出す
  const registeredKijiIds = []
  for (const detail of this.$$getMyFolderInfo('FAVORITE_KIJI', 'detail')) {
    registeredKijiIds.push(detail.kijiId)
  }

  // 登録済みの記事を除外する
  const kijiIds = []
  if (kijiId !== '') {
    if (!registeredKijiIds.includes(kijiId)) {
      kijiIds.push(kijiId)
    }
  } else {
    for (const info of this.kijiIdBox) {
      if (!registeredKijiIds.includes(info[0])) {
        kijiIds.push(info[0])
      }
    }
  }
  console.log(kijiIds)
  if (kijiIds.length === 0) {
    alert('登録済みの記事です。')
    return
  }

  // 登録上限数を超えないかチェック
  if (
    this.$$getMyFolderInfo('FAVORITE_KIJI', 'detailCount') + kijiIds.length >
    this.$$const.MY_FOLDER.FAVORITE_KIJI_LIMIT
  ) {
    // メッセージ表示
    this.$nuxt.$emit('setAlertId', 'favoriteKijiIsFull')
    return
  }

  // 保存情報を整形する
  const json = { detail: [] as MyFolder.favoriteKijiDetailIF[] }
  for (const kijiId of kijiIds) {
    console.log(kijiId)
    json.detail.push({
      uuid: '',
      updatetime: dayjs().format('YYYYMMDDHHmmss'),
      kijiId,
      memo: JSON.stringify(''),
    })
  }
  const hznJson = JSON.stringify(json)
  console.log(hznJson)

  // マイフォルダ保存情報を更新する
  const res2 = await this.$$addMyFolderInfo(
    this.$$const.API.kinouId.FAVORITE_KIJI,
    hznJson
  )

  if (res2) {
    if (kijiId !== '') {
      // マイフォルダJSONを取得する
      await this.$$getMyFolderJson(['FAVORITE_KIJI'])

      // 強制的に再レンダリング
      this.$forceUpdate()

      // uuidを調べる
      let uuid = ''
      for (const detail of this.$$getMyFolderInfo('FAVORITE_KIJI', 'detail')) {
        if (detail.kijiId === kijiId) {
          uuid = detail.uuid
          break
        }
      }

      // メッセージ用に記事詳細をセット
      for (const list of this.detailApi.result.lists) {
        if (list.KijiId === kijiId) {
          list.uuid = uuid
          this.$nuxt.$emit('setFavoriteKijiInfo', list)
          break
        }
      }

      // メッセージ表示
      this.$nuxt.$emit('setAlertId', 'addFavoriteKiji')
    } else {
      // メッセージ表示
      this.$nuxt.$emit('setAlertId', 'addFavoriteKijis')
    }
  }
}

/**
 * お気に入り記事のメモを更新する
 * @params {string} uuid
 * @params {string} kijiId
 * @params {number} i
 * @return {Promise<void>}
 */
Vue.prototype.$$updateFavoriteKijiMemo = async function (
  uuid: string,
  kijiId: string,
  i: number = 0
): Promise<void> {
  console.log('$$updateFavoriteKijiMemo', uuid, kijiId)

  if (!kijiId) {
    return
  }

  // 保存情報を整形する
  const memo = this.memo[uuid] ? this.memo[uuid] : ''
  const json = { detail: [] as MyFolder.favoriteKijiDetailIF[] }
  json.detail.push({
    uuid,
    updatetime: dayjs().format('YYYYMMDDHHmmss'),
    kijiId,
    memo: JSON.stringify(memo),
  })
  const hznJson = JSON.stringify(json)
  console.log(hznJson)

  // マイフォルダ保存情報を更新する
  const res = await this.$$updateMyFolderInfo(
    this.$$const.API.kinouId.FAVORITE_KIJI,
    uuid,
    hznJson
  )

  if (res) {
    if (this.checkedMemos !== undefined) {
      this.detail.FAVORITE_KIJI[i].memo = JSON.stringify(memo)
      this.checkedMemos = []
    }
    if (this.closeFlg !== undefined) {
      this.closeFlg = 1
    }
  }
}

/**
 * ノートプラスを追加する
 * @return {Promise<boolean>}
 */
Vue.prototype.$$addNotePlus = async function (): Promise<boolean> {
  console.log('$$addNotePlus')

  // マイフォルダを開いているかチェック
  if (!this.$$getLoginInfo('S_HZNFOLDER')) {
    // メッセージ表示
    // this.$nuxt.$emit('setAlertId', 'notePlusNotOpen')
    return false
  }

  // マイフォルダJSONを取得する
  await this.$$getMyFolderJson(['NOTE_PLUS'])

  // 登録上限数を超えないかチェック
  if (
    this.$$getMyFolderInfo('NOTE_PLUS', 'detailCount') + 1 >
    this.$$const.MY_FOLDER.NOTE_PLUS_LIMIT
  ) {
    // メッセージ表示
    // this.$nuxt.$emit('setAlertId', 'notePlusIsFull')
    return false
  }

  // 保存情報を整形する
  const json = { detail: [] as MyFolder.notePlusDetailIF[] }
  const detail = this.$$getInitialValue('notePlusDetail')
  detail.updatetime = dayjs().format('YYYYMMDDHHmmss')
  detail.templateNo = this.notePlus.templateNo
  detail.name = this.notePlus.name
  json.detail.push(detail)
  const hznJson = JSON.stringify(json)
  console.log(hznJson)

  // マイフォルダ保存情報を更新する
  return await this.$$addMyFolderInfo(
    this.$$const.API.kinouId.NOTE_PLUS,
    hznJson
  )
}

/**
 * ノートプラスを取得する
 * @return {Promise<void>}
 */
Vue.prototype.$$getNotePlus = async function (): Promise<void> {
  console.log('getNotePlus')
  // 原本を初期化
  this.org = this.$$getInitialValue('notePlusDetail')

  // マイフォルダを開いているかチェック
  if (!this.$$getLoginInfo('S_HZNFOLDER')) {
    throw new Error('マイフォルダが開いていません。')
  }

  // マイフォルダJSONを取得する
  await this.$$getMyFolderJson(['NOTE_PLUS'])

  // 原本をセット
  for (const detail of this.$$getMyFolderInfo('NOTE_PLUS', 'detail')) {
    console.log('detail', detail)
    if (detail.uuid === this.uuid) {
      this.org = detail
    }
  }

  if (this.org.uuid === '') throw new Error('データが壊れています。')
}

/**
 * ノートプラスの編集内容を更新（保存）する
 * @return {Promise<void>}
 */
Vue.prototype.$$updateNotePlus = async function (): Promise<void> {
  console.log('updateNotePlus')
  // 保存情報を整形する
  const json = { detail: [] as MyFolder.notePlusDetailIF[] }
  json.detail.push({
    uuid: this.uuid,
    updatetime: dayjs().format('YYYYMMDDHHmmss'),
    templateNo: this.templateNo,
    name: this.merged.name,
    image: this.merged.image,
    font: this.merged.font,
    color: this.merged.color,
    text: this.merged.text,
  })
  const hznJson = JSON.stringify(json)
  console.log(hznJson)

  // マイフォルダ保存情報を更新する
  await this.$$updateMyFolderInfo(
    this.$$const.API.kinouId.NOTE_PLUS,
    this.uuid,
    hznJson
  )
}

Vue.prototype.$$addNotePlus2 = async function (): Promise<boolean> {
  const detail = this.$$getInitialValue('notePlusDetail')
  detail.uuid = 'aaaaaaaaaaaaaaaaaaaaaaaaa'
  detail.templateNo = 0
  this.org = detail

  const item = window.localStorage.getItem('noteplus-test')
  if (item === null) {
    detail.updatetime = dayjs().format('YYYYMMDDHHmmss')
    const json = { detail: [] as MyFolder.notePlusDetailIF[] }
    json.detail.push(detail)
    const hznJson = JSON.stringify(json)
    console.log(hznJson)
    window.localStorage.setItem('noteplus-test', hznJson)
  }
  return true
}
Vue.prototype.$$getNotePlus2 = async function (): Promise<void> {
  this.org = this.$$getInitialValue('notePlusDetail')
  const item = window.localStorage.getItem('noteplus-test')
  if (item === null) {
    throw new Error('ローカルストレージからデータ取得失敗')
  }
  const json = JSON.parse(item)
  storeMyFolderInfo('NOTE_PLUS', json as MyFolder.notePlusJsonIF)

  for (const detail of this.$$getMyFolderInfo('NOTE_PLUS', 'detail')) {
    console.log('detail', detail)
    if (detail.uuid === this.uuid) {
      this.org = detail
    }
  }
  if (this.org.uuid === '') throw new Error('データが壊れています。')
}
Vue.prototype.$$updateNotePlus2 = async function (): Promise<void> {
  const json = { detail: [] as MyFolder.notePlusDetailIF[] }
  json.detail.push({
    uuid: this.uuid,
    updatetime: dayjs().format('YYYYMMDDHHmmss'),
    templateNo: this.templateNo,
    name: this.merged.name,
    image: this.merged.image,
    font: this.merged.font,
    color: this.merged.color,
    text: this.merged.text,
  })
  const hznJson = JSON.stringify(json)
  console.log(hznJson)
  window.localStorage.setItem('noteplus-test', hznJson)
}

/**
 * 日付を取得する
 * @params {string} datetime
 * @params {string} format
 * @return {string}
 */
Vue.prototype.$$getDate = function (datetime: string, format: string): string {
  return dayjs(datetime, 'YYYYMMDDHHmmss').format(format)
}

/**
 * 初期値を取得する
 * @params {string} key
 * @return {any}
 */
Vue.prototype.$$getInitialValue = function (key: string): any {
  if (key === 'detail') {
    return {
      QUOTE: [],
      PRESEARCH: [],
      FAVORITE_KIJI: [],
      NOTE_PLUS: [],
    } as MyFolder.detailIF
  } else if (key === 'searchApi') {
    return {
      query: {
        srchInfo: [
          {
            dspNum: null,
            dspOrder: null,
          },
        ],
      },
      condition: [],
      result: [] as DangoResponse.searchResultIF[],
      totalCount: -1,
      page: 1,
      start: null,
      end: null,
    }
  } else if (key === 'detailApi') {
    return {
      query: {},
      result: {
        lists: [] as DangoResponse.searchKijiListsIF[],
      },
      result2: {} as any,
      itemInfo: [] as string[][],
      srchId: this.$$const.API.srchId.KIJI,
      srchOpt: this.$$const.API.srchOpt.NO_HISTORY,
      dspOrder: this.$$const.API.dspOrder.NEWEST_TO_OLDEST,
      keywords: [],
    }
  } else if (key === 'page') {
    return {
      QUOTE: 1,
      PRESEARCH: 1,
      FAVORITE_KIJI: 1,
      NOTE_PLUS: 1,
    }
  } else if (key === 'srchCondition') {
    return {
      keyword: '',
      dspNum: '',
      dspOrder: '',
      hakkouDate: '',
      shishiNames: [],
      kanshuNames: [],
      menName: '',
      kirinukiUseReq: '',
    }
  } else if (key === 'notePlusDetail') {
    return {
      uuid: '',
      updatetime: '',
      templateNo: 0,
      name: '',
      image: '',
      font: {},
      color: {},
      text: {},
    }
  } else {
    return null
  }
}

/**
 * ログインページのページパスをローカルストレージに保存する
 * @return {void}
 */
Vue.prototype.$$setLoginPagePath = function (): void {
  // ローカルストレージにページパスを保存する
  window.localStorage.setItem(
    this.$$const.STORAGE_KEY.LOGIN_PATH,
    this.$route.path
  )
}

/**
 * 文字を統一する
 * @params {string} str
 * @return {string}
 */
Vue.prototype.$$unifyChar = function (str: string): string {
  // for (let i = 0; i < str.length; i++) {
  //  console.log(str[i], str.charCodeAt(i).toString(16))
  // }

  // 全角チルダ"～"（\uFF5E）に統一
  str = str.replace(/\u007E/g, '\uFF5E') // チルダ"~"
  str = str.replace(/\u301C/g, '\uFF5E') // 波ダッシュ"〜"

  // 全角ハイフン"－"(\uFF0D) に統一
  str = str.replace(/\u002D/g, '\uFF0D') // ハイフンマイナス"-"
  str = str.replace(/\u2010/g, '\uFF0D') // ハイフン"‐"
  str = str.replace(/\u2012/g, '\uFF0D') // ダッシュ"‒"
  str = str.replace(/\u2013/g, '\uFF0D') // 半角ダッシュ"–"
  str = str.replace(/\u207B/g, '\uFF0D') // マイナス"⁻"
  str = str.replace(/\u208B/g, '\uFF0D') // 下付き文字マイナス"₋"
  str = str.replace(/\u2212/g, '\uFF0D') // 全角マイナス"−"
  str = str.replace(/\uFFE3/g, '\uFF0D') // 全角オーバーライン"￣"

  // 水平バー"―"（\u2015）に統一
  str = str.replace(/\u2014/g, '\u2015') // 全角ダッシュ"—"

  return str
}
