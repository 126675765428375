/* eslint no-extend-native: ["error", { "exceptions": ["String"] }] */

let hankakuRule = require('~/assets/config/hankaku_rule.json')
let convertRule = require('~/assets/config/convert_rule.json')

// インターフェースを追加
declare global {
  interface String {
    $trim(): string
    $setOperator(quoteMode: number): string
    $convertString(
      quoteMode: number,
      itaijiFlg: number,
      dougigoFlg: number
    ): string
    $convertMidPoint(quoteMode: number): string
    $explodeByOperator(quoteMode: number, flg1: number, flg2: number): string[]
    $convertHtmlspecialchars(): string
    $convertNewlineCode(): string
  }
}

/**
 * @type {Number} 異体字の単語展開上限
 */
const itaijiWordLimit: number = 3

/**
 * @type {Number} 異体字の文字展開上限
 */
const itaijiCharLimit: number = 3

/**
 * @type {Number} 同義語の単語展開上限
 */
const dougigoWordLimit: number = 3

/**
 * @type {RegExp} キーワードに使用可能な演算子（正規表現）
 */
const operatorRegex: RegExp = /[+()&#\u{20}\u{3000}]/gu

/**
 * @type {Object} 辞書
 */
const dictionary: { [key: string]: { [char: string]: any } } = {
  hankaku: {},
  dougigo: {},
  itaiji: {},
  yousoku: {},
  potsu: {},
}

/**
 * 配列の要素をユニークにする
 * @params {array} array
 * @return {array} str
 */
const uniqueArray = (array: string[]): string[] => {
  return array.filter(
    (el: string, idx: number, self: string[]) => self.indexOf(el) === idx
  )
}

/**
 * 辞書を初期化する
 * @return {void}
 */
const initDictionary = (): void => {
  if (Object.keys(dictionary.dougigo).length) {
    return
  }
  dictionary.hankaku = hankakuRule
  hankakuRule = null

  for (const key of ['dougigo', 'itaiji']) {
    const obj: { [char: string]: string[] } = {}
    for (let row of convertRule[key]) {
      // 重複する単語を除去
      row = uniqueArray(row)

      for (const item of row) {
        // キー文字列を配列の先頭にする
        const row2: string[] = [item].concat(
          row.filter((el: string) => el !== item)
        )
        if (obj[item] !== undefined) {
          // 連想配列に登録済みの用語であればマージ
          obj[item] = obj[item].concat(row2)
          // 重複している用語を除去
          obj[item] = obj[item].filter(
            (el, idx, self) => self.indexOf(el) === idx
          )
        } else {
          // 連想配列に登録
          obj[item] = row2
        }
      }
      dictionary[key] = obj
    }
  }
  dictionary.yousoku = convertRule.yousoku
  dictionary.potsu = convertRule.potsu
  convertRule = null
}

/**
 * 演算子区切りで文字列を分割する
 * @param {string} str 対象文字列
 * @param {number} quoteMode ダブルクォーテーション対応モード
 * @return {array} words
 */
const explodeByOperator = (str: string, quoteMode: number): string[] => {
  let words: string[] = []
  let word: string = ''

  if (quoteMode) {
    // ダブルクォーテーション対応モード
    // ダブルクォーテーション囲まれている単語を抜き出す
    let quoteFlg: boolean = false
    for (const char of str.split('')) {
      if (char === '"') {
        if (quoteFlg) {
          quoteFlg = false
          word += char
          words.push(word)
          word = ''
        } else {
          quoteFlg = true
          if (word !== '') {
            words.push(word)
          }
          word = char
        }
      } else {
        word += char
      }
    }
    if (quoteFlg) {
      word += '"'
    }
    if (word !== '') {
      words.push(word)
    }

    // ダブルクォートで囲まれていない単語を演算子区切りで分割する
    if (quoteMode === 2) {
      const tmpWords: string[] = []
      for (const word of words) {
        if (word.match(/^".*?"$/)) {
          tmpWords.push(word)
          continue
        } else {
          let tmpWord = ''
          for (const char of word.split('')) {
            if (char.match(operatorRegex) !== null) {
              if (tmpWord !== '') {
                tmpWords.push(tmpWord)
                tmpWord = ''
              }
              tmpWords.push(char)
            } else {
              tmpWord += char
            }
          }
          if (tmpWord !== '') {
            tmpWords.push(tmpWord)
          }
        }
      }
      words = tmpWords
    }
  } else {
    // 演算子区切りで文字列を分割する
    for (const char of str.split('')) {
      if (char.match(operatorRegex) !== null) {
        if (word !== '') {
          words.push(word)
          word = ''
        }
        words.push(char)
      } else {
        word += char
      }
    }
    if (word !== '') {
      words.push(word)
    }
  }
  return words
}

/**
 * 演算子に変換する
 * @params {string} str 対象文字列
 * @return {string}
 */
const convert2Operator = (str: string): string => {
  return (
    str
      // エスケープされた半角記号を全角記号に変換
      .replace(/\\&/g, '＆')
      .replace(/\\'/g, '’')
      .replace(/\\"/g, '”')
      .replace(/\\</g, '＜')
      .replace(/\\>/g, '＞')
      .replace(/\\\+/g, '＋')
      .replace(/\\#/g, '＃')
      .replace(/\\\(/g, '（')
      .replace(/\\\)/g, '）')

      // 全角スペースを半角スペースに変換
      .replace(/\u{3000}/gu, '\u{20}')

      // 「 AND 」「^ AND」「 AND$」を「&」に変換
      .replace(/\u{20}AND\u{20}/gu, '\u{20}&\u{20}')
      .replace(/\u{20}AND\u{20}/gu, '\u{20}&\u{20}')
      .replace(/^AND\u{20}/gu, '&\u{20}')
      .replace(/\u{20}AND$/gu, '\u{20}&')
      .replace(/^AND$/gu, '&')
      .replace(/\(AND\u{20}/gu, '(&')
      .replace(/\u{20}AND\)/gu, '&)')

      // 「 OR 」「^ OR」「 OR$」を「+」に変換
      .replace(/\u{20}OR\u{20}/gu, '\u{20}+\u{20}')
      .replace(/\u{20}OR\u{20}/gu, '\u{20}+\u{20}')
      .replace(/^OR\u{20}/gu, '+\u{20}')
      .replace(/\u{20}OR$/gu, '\u{20}+')
      .replace(/^OR$/gu, '+')
      .replace(/\(OR\u{20}/gu, '(+')
      .replace(/\u{20}OR\)/gu, '+)')

      // 「 NOT 」「^ NOT」「 NOT$」を「#」に変換
      .replace(/\u{20}NOT\u{20}/gu, '\u{20}#\u{20}')
      .replace(/\u{20}NOT\u{20}/gu, '\u{20}#\u{20}')
      .replace(/^NOT\u{20}/gu, '#\u{20}')
      .replace(/\u{20}NOT$/gu, '\u{20}#')
      .replace(/^NOT$/gu, '#')
      .replace(/\(NOT\u{20}/gu, '(#')
      .replace(/\u{20}NOT\)/gu, '#)')

      // 連続する半角スペースを纏める
      .replace(/(\u{20})+/gu, '\u{20}')

      // 「 演算子」「演算子 」の半角スペースを除去
      .replace(/\u{20}&/gu, '&')
      .replace(/&\u{20}/gu, '&')
      .replace(/\u{20}\+/gu, '+')
      .replace(/\+\u{20}/gu, '+')
      .replace(/\u{20}#/gu, '#')
      .replace(/#\u{20}/gu, '#')
      .replace(/\u{20}\(/gu, '&(')
      .replace(/\(\u{20}/gu, '(')
      .replace(/\u{20}\)/gu, ')')
      .replace(/\)\u{20}/gu, ')&')

      // 半角スペースを&に変換
      .replace(/(\u{20})+/gu, '&')
  )
}

/**
 * 正規表現の特殊文字をエスケープする
 * @params {string} str 対象文字列
 * @return {string}
 */
const escapeRegExp = (str: string): string => {
  return str.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&')
}

/**
 * 半角文字を変換する
 * @params {string} str 対象文字列
 * @params {number} quoteMode クォーテーション対応モード
 * @params {number} baseFlg ベース文字列を含めるかどうか（1:含める、0:含めない）
 * @return {string}
 */
const convertHankaku = (
  str: string,
  quoteMode: number,
  baseFlg: number = 1
): string => {
  // 辞書を初期化
  initDictionary()

  // 演算子区切りで文字列を分割する
  const words: string[] = explodeByOperator(str, quoteMode * 2)

  let res: string = ''
  for (const word of words) {
    // クォートモードかつダブルクォーテーションで囲まれている場合は無加工で連結
    if (quoteMode && word.match(/^".*?"$/)) {
      res += word
      continue
    }

    // 演算子の場合は無加工で連結
    if (word.match(operatorRegex) !== null) {
      res += word
      continue
    }

    // 単語を分割
    let chars: string[] = word.split('')

    // 重複する文字を除去
    chars = uniqueArray(chars)

    // 半角文字を含むかチェック
    const matches: { [matchChar: string]: string[] }[] = []
    for (const char of chars) {
      if (dictionary.hankaku[char] !== undefined) {
        matches.push({ [char]: dictionary.hankaku[char] })
        continue
      }
    }

    // 変換対象の文字がなければ次へ
    if (matches.length === 0) {
      res += word
      continue
    }

    // 変換後の単語配列
    let newWords: string[] = []
    if (baseFlg === 1) {
      newWords = [word]
    }

    // 半角文字を変換する
    if (matches.length > 0) {
      let convertedWords: string[] = [word]
      for (const match of matches) {
        for (const matchChar in match) {
          convertedWords = convertedWords.map((item) =>
            item.replace(
              new RegExp(escapeRegExp(matchChar), 'g'),
              String(match[matchChar])
            )
          )
        }
      }
      // ベースとなる単語配列に変換後の単語をマージ
      newWords = newWords.concat(convertedWords)
    }
    console.log(newWords)

    // 変換した単語を連結
    if (words.length > 1) {
      res += '('
    }
    res += newWords.join('+')
    if (words.length > 1) {
      res += ')'
    }
  }
  return res
}

/**
 * 異体字を変換する
 * @params {string} str 対象文字列
 * @params {number} quoteMode クォーテーション対応モード
 * @return {string}
 */
const convertItaiji = (str: string, quoteMode: number): string => {
  // 辞書を初期化
  initDictionary()

  // 演算子区切りで文字列を分割する
  const words: string[] = explodeByOperator(str, quoteMode * 2)

  let res: string = ''
  let wordCnt: number = 0
  for (const word of words) {
    // クォートモードかつダブルクォーテーションで囲まれている場合は無加工で連結
    if (quoteMode && word.match(/^".*?"$/)) {
      res += word
      continue
    }

    // 演算子の場合は無加工で連結
    if (word.match(operatorRegex) !== null) {
      res += word
      continue
    }

    // ベースとなる単語配列を作成
    let baseWords: string[] = [word]

    // 単語を分割
    let chars: string[] = word.split('')

    // 重複する文字を除去
    chars = uniqueArray(chars)

    // 異体字、拗促音を含むかチェック
    const matches1: { [matchChar: string]: string[] }[] = []
    const matches2: { [matchChar: string]: string }[] = []
    let charCnt: number = 0
    for (const char of chars) {
      if (dictionary.itaiji[char] !== undefined) {
        matches1.push({ [char]: dictionary.itaiji[char] })
      } else if (dictionary.yousoku[char] !== undefined) {
        matches2.push({ [char]: dictionary.yousoku[char] })
      } else {
        continue
      }
      // 文字展開上限の場合はbreak
      if (++charCnt > itaijiCharLimit) {
        break
      }
    }

    // 変換対象の文字がなければ次へ
    if (matches1.length === 0 && matches2.length === 0) {
      res += word
      continue
    }

    // 単語展開上限を超えている場合は次へ
    if (wordCnt++ >= itaijiWordLimit) {
      res += word
      continue
    }

    // 異体字、中点を変換する
    if (matches1.length > 0) {
      for (const match of matches1) {
        for (const matchChar in match) {
          let convertedWords: string[] = []
          for (const conversionChar of match[matchChar]) {
            // ベースとなる単語配列を変換する
            convertedWords = convertedWords.concat(
              baseWords.map((item) =>
                item.replace(
                  new RegExp(escapeRegExp(matchChar), 'g'),
                  conversionChar
                )
              )
            )
          }
          // ベースとなる単語配列に変換後の単語をマージ
          baseWords = baseWords.concat(convertedWords)

          // 重複する単語を除去
          baseWords = uniqueArray(baseWords)
        }
      }
    }

    // 拗促音を変換する
    if (matches2.length > 0) {
      let convertedWords: string[] = baseWords
      for (const match of matches2) {
        for (const matchChar in match) {
          convertedWords = convertedWords.map((item) =>
            item.replace(
              new RegExp(escapeRegExp(matchChar), 'g'),
              String(match[matchChar])
            )
          )
        }
      }
      // ベースとなる単語配列に変換後の単語をマージ
      baseWords = baseWords.concat(convertedWords)

      // 重複する単語を除去
      baseWords = uniqueArray(baseWords)
    }

    // 変換した単語を連結
    if (words.length > 1) {
      res += '('
    }
    res += baseWords.join('+')
    if (words.length > 1) {
      res += ')'
    }
  }
  return res
}

/**
 * 同義語を変換する
 * @params {string} str 対象文字列
 * @params {number} quoteMode クォーテーション対応モード
 * @return {string}
 */
const convertDougigo = (str: string, quoteMode: number): string => {
  // 辞書を初期化
  initDictionary()

  // 演算子区切りで文字列を分割する
  const words: string[] = explodeByOperator(str, quoteMode * 2)

  let res: string = ''
  let wordCnt: number = 0
  for (const word of words) {
    // クォートモードかつダブルクォーテーションで囲まれている場合は無加工で連結
    if (quoteMode && word.match(/^".*?"$/)) {
      res += word
      continue
    }

    // 演算子又は同義語未定義の場合は無加工で連結
    if (
      word.match(operatorRegex) !== null ||
      dictionary.dougigo[word] === undefined
    ) {
      res += word
      continue
    }

    // 単語展開上限を超えている場合は次へ
    if (wordCnt++ >= dougigoWordLimit) {
      res += word
      continue
    }

    // 変換した単語を連結
    if (words.length > 1) {
      res += '('
    }
    res += dictionary.dougigo[word].join('+')
    if (words.length > 1) {
      res += ')'
    }
  }
  return res
}

/**
 * 中点を変換する
 * @params {string} str 対象文字列
 * @params {number} quoteMode クォーテーション対応モード
 * @return {string}
 */
const convertMidPoint = (str: string, quoteMode: number): string => {
  // 辞書を初期化
  initDictionary()

  // 演算子区切りで文字列を分割する
  const words: string[] = explodeByOperator(str, quoteMode * 2)

  let res: string = ''
  for (const word of words) {
    // クォートモードかつダブルクォーテーションで囲まれている場合は無加工で連結
    if (quoteMode && word.match(/^".*?"$/)) {
      res += word
      continue
    }

    // 演算子の場合は無加工で連結
    if (word.match(operatorRegex) !== null) {
      res += word
      continue
    }

    // ベースとなる単語配列を作成
    let baseWords: string[] = [word]

    // 単語を分割
    let chars: string[] = word.split('')

    // 重複する文字を除去
    chars = uniqueArray(chars)

    // 異体字、中点、拗促音を含むかチェック
    const matches1: { [matchChar: string]: string[] }[] = []
    for (const char of chars) {
      if (dictionary.potsu[char] !== undefined) {
        matches1.push({ [char]: [dictionary.potsu[char]] })
      }
    }

    // 変換対象の文字がなければ次へ
    if (matches1.length === 0) {
      res += word
      continue
    }

    // 中点を変換する
    if (matches1.length > 0) {
      for (const match of matches1) {
        for (const matchChar in match) {
          let convertedWords: string[] = []
          for (const conversionChar of match[matchChar]) {
            // ベースとなる単語配列を変換する
            convertedWords = convertedWords.concat(
              baseWords.map((item) =>
                item.replace(
                  new RegExp(escapeRegExp(matchChar), 'g'),
                  conversionChar
                )
              )
            )
          }
          // ベースとなる単語配列に変換後の単語をマージ
          baseWords = baseWords.concat(convertedWords)

          // 重複する単語を除去
          baseWords = uniqueArray(baseWords)
        }
      }
    }

    // 変換した単語を連結
    if (words.length > 1) {
      res += '('
    }
    res += baseWords.join('+')
    if (words.length > 1) {
      res += ')'
    }
  }
  return res
}

/**
 * トリミングする
 * @return {string}
 */
String.prototype.$trim = function (): string {
  const str = String(this)
  return str.replace(/^\s+|\s+$/g, '') // 前後の空白を除去
}

/**
 * 演算子をセットする
 * @param {number} quoteMode ダブルクォーテーション対応モード
 * @return {string} res
 */
String.prototype.$setOperator = function (quoteMode: number): string {
  const str = String(this)
  let res: string = ''

  if (quoteMode) {
    // 演算子区切りで文字列を分割する
    const words: string[] = explodeByOperator(str, quoteMode)

    for (const word of words) {
      if (word.match(/^".*?"$/)) {
        res += word
        continue
      }
      res += convert2Operator(word)
    }
  } else {
    res = convert2Operator(str)
  }
  return res
}

/**
 * 文字を変換する
 * @param {number} quoteMode ダブルクォーテーション対応モード
 * @param {number} itaijiFlg 異体字変換フラグ
 * @param {number} dougigoFlg 同義語変換フラグ
 * @params {number} baseFlg ベース文字列を含めるかどうか（1:含める、0:含めない
 * @return {string}
 */
String.prototype.$convertString = function (
  quoteMode: number,
  itaijiFlg: number,
  dougigoFlg: number,
  baseFlg: number = 1
): string {
  let str = String(this)

  // 半角文字変換
  str = convertHankaku(str, quoteMode, baseFlg)

  // 異体字変換
  if (itaijiFlg === 1) {
    str = convertItaiji(str, quoteMode)
  }

  // 中点変換
  if (itaijiFlg === 1) {
    str = convertMidPoint(str, quoteMode)
  }

  // 同義語変換
  if (dougigoFlg === 1) {
    str = convertDougigo(str, quoteMode)
  }

  return str
}

/**
 * 中点を変換する
 * @param {number} quoteMode ダブルクォーテーション対応モード
 * @return {string}
 */
String.prototype.$convertMidPoint = function (quoteMode: number): string {
  let str = String(this)
  // 中点変換
  str = convertMidPoint(str, quoteMode)

  return str
}

/**
 * 演算子区切りで文字列を分割する
 * @param {number} quoteMode ダブルクォーテーション対応モード
 * @param {number} flg1 1: 演算子を含まない
 * @param {number} flg2 1: ダブルクォーテーション内の文字列を含まない
 * @return {array} words
 */
String.prototype.$explodeByOperator = function (
  quoteMode: number,
  flg1: number,
  flg2: number
): string[] {
  const str = String(this)
  let words: string[] = explodeByOperator(str, quoteMode * 2)
  if (flg2 >= 1) {
    words = words.map((word: string) => word.replace(/^".*?"$/, '""'))
  }
  if (flg1) {
    words = words.filter((word: string) => !word.match(operatorRegex))
  }
  return words
}

/**
 * HTMLの特殊文字をHTMLエンティティに変換する
 * @return {string}
 */
String.prototype.$convertHtmlspecialchars = function (): string {
  const str = String(this)
  return (str + '')
    .replace(/&/g, '&amp;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
}

/**
 * 改行コードを変換する
 * @return {string}
 */
String.prototype.$convertNewlineCode = function (): string {
  const str = String(this)
  return (str + '').replace(/\r\n/g, '<br>').replace(/\n/g, '<br>')
}

export {}
